import * as Sentry from "@sentry/react"
import axios from "axios"

Sentry.init({
    dsn: "https://09374547e5431173cb14c7b5dcac607a@o4507537248485376.ingest.de.sentry.io/4507537258512464",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

window.axios = axios

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"
